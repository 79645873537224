<template>
    <div>
        <div class="active">
            <div class="active-text">ACTIVITY SUPPORT </div>
            <div class="active-text">活动支持</div>
            <div class="active-text">
                长脸叔叔提供盟校年度美术行业高峰论坛、夏令营、国际创意画展等活动，并参与中国艺术教育行业博览会、国际文化创意产业博览会、中国幼教展、国际图书展、国际学前教育产业博览会等大型活动，不仅可以促进全国盟校的交流学习，提升盟校在当地的竞争力可，还能进一步提升品牌知名度与影响力，推动思维美育行业的大力发展。月度活动方案，全国门店统一执行，提高品牌宣传效益。
            </div>
            <div class="active-list">
                <div class="active-list-back" v-for="item in activeList" :key="item.id"
                    :style="{'backgroundImage':'url('+ $imgURL + item.hd_image+')'}" @click="active=item.id-1">
                    <div>
                        <p>{{item.name}}</p>
                        <p>{{item.title}}</p>
                        <p>{{item.desc}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 画展 -->
        <div class="title" v-if="active==0"
            :style="{'backgroundImage':'url('+ $imgURL + activeList[active].bj_image+')'}">
            <div class="back">
                <div class="poster" :style="{'backgroundImage':'url('+ $imgURL + activeList[active].hb_image+')'}">
                </div>
            </div>
            <div class="right">
                <div class="right-title">
                    <p>画展</p>
                    <p>WATCH THE EXHIBITION</p>
                    <p></p>
                </div>
                <div class="right-list">
                    <div class="right-list-back" v-for="item in 3" :key="item">
                        <el-carousel trigger="click" height="150px" class="right-list-back-content">
                            <el-carousel-item
                                v-for="item in activeList[active].lbt_images.split(',').slice((item-1)*2,item*2)"
                                :key="item">
                                <img :src="$imgURL+item" alt="">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
        </div>
        <!-- 年会 -->
        <div class="center" v-if="active==1">
            <div class="center-left">
                <div class="center-left-back">
                    <img v-for="(item,index) in activeList[active].lbt_images.split(',')" :key="item" ref="nhimglist"
                        :src="$imgURL+item" alt="" @click="imgreset(index,'nh')">
                </div>
            </div>
            <div class="center-right">
                <div class="center-right-back">

                    <div :style="{'backgroundImage':'url('+ $imgURL + activeList[active].hb_image+')'}"></div>
                </div>
                <div class="center-right-bottom">
                    <p>峰会年会</p>
                    <p>ANNUAL SUMMIT MEETING</p>
                    <p></p>
                </div>
            </div>
        </div>

        <!-- 秋游 -->
        <div class="bottom" v-if="active==2">
            <div class="bottom-title" :style="{'backgroundImage':'url('+ $imgURL + activeList[active].bj_image+')'}">
                <div :style="{'backgroundImage':'url('+ $imgURL + activeList[active].hb_image+')'}"
                    class="bottom-title-poster"></div>
            </div>
            <div class="bottom-center">
                <div class="bottom-center-autumn">
                    <p>秋游</p>
                    <p>AUTUMN OUTING</p>
                    <p></p>
                </div>

                <div class="bottom-center-imgs">
                    <div class="bottom-center-imgs-back" v-for="item in 3" :key="item.id">
                        <el-carousel trigger="click" height="150px" class="bottom-center-imgs-back-content">
                            <el-carousel-item
                                v-for="item in activeList[active].lbt_images.split(',').slice((item-1)*2,item*2)"
                                :key="item">
                                <img :src="$imgURL+item" alt="">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>

            <div class="bottom-bottom">

            </div>
        </div>

        <!-- 节日活动 -->
        <div class="title" v-if="active==3"
            :style="{'backgroundImage':'url('+ $imgURL + activeList[active].bj_image+')'}">
            <div class="back" style="background:#F88C4B">
                <div class="poster" :style="{'backgroundImage':'url('+ $imgURL + activeList[active].hb_image+')'}">
                </div>
            </div>
            <div class="right">
                <div class="right-title">
                    <p>节日活动</p>
                    <p>FESTIVAL ACTIVITIES</p>
                    <p style="background:#F88C4B"></p>
                </div>
                <div class="right-list">
                    <div class="right-list-back" v-for="item in 3" :key="item">
                        <el-carousel trigger="click" height="150px" class="right-list-back-content">
                            <el-carousel-item
                                v-for="item in activeList[active].lbt_images.split(',').slice((item-1)*2,item*2)"
                                :key="item">
                                <img :src="$imgURL+item" alt="">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
        </div>
        <!-- 写生 -->
        <div class="center" v-if="active==4">
            <div class="center-left" style="background:#FCC2C0">
                <div class="center-left-back">
                    <img v-for="(item,index) in activeList[active].lbt_images.split(',')" :key="item" ref="xsimglist"
                        :src="$imgURL+item" alt="" @click="imgreset(index,'xs')">
                </div>
            </div>
            <div class="center-right">
                <div class="center-right-back" style="background:#FCC2C0">
                    <div :style="{'backgroundImage':'url('+ $imgURL + activeList[active].hb_image+')'}"></div>
                </div>
                <div class="center-right-bottom">
                    <p>写生</p>
                    <p>GO OUT SKETCHING</p>
                    <p style="background:#FCC2C0;width: 100%;"></p>
                </div>
            </div>
        </div>

        <!-- 异业合作 -->
        <div class="bottom" v-if="active==5">
            <div class="bottom-title" :style="{'backgroundImage':'url('+ $imgURL + activeList[active].bj_image+')'}">
                <div :style="{'backgroundImage':'url('+ $imgURL + activeList[active].hb_image+')'}"
                    class="bottom-title-poster"></div>
            </div>
            <div class="bottom-center">
                <div class="bottom-center-autumn">
                    <p>异业合作</p>
                    <p>DIFFERENT INDUSTRY COOPERATION</p>
                    <p style="background:#7590DE"></p>
                </div>

                <div class="bottom-center-imgs">
                    <div class="bottom-center-imgs-back" style="background:#7590DE" v-for="item in 3" :key="item.id">
                        <el-carousel trigger="click" height="150px" class="bottom-center-imgs-back-content">
                            <el-carousel-item
                                v-for="item in activeList[active].lbt_images.split(',').slice((item-1)*2,item*2)"
                                :key="item">
                                <img :src="$imgURL+item" alt="">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>

            <div class="bottom-bottom">

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "activity",
    data() {
        return {
            active: 0,
            activeList: []
        }
    },
    mounted() {
        this.getactivityList()
    },
    methods: {
        imgreset(i, flag) {
            if (flag === 'nh') {
                let item = this.$refs.nhimglist[0].src
                this.$refs.nhimglist[0].src = this.$refs.nhimglist[i].src
                this.$refs.nhimglist[i].src = item
            }
            else if (flag === 'xs') {
                let item = this.$refs.xsimglist[0].src
                this.$refs.xsimglist[0].src = this.$refs.xsimglist[i].src
                this.$refs.xsimglist[i].src = item
            }
        },
        gedate(url, data) {
            return new Promise((resove, reject) => {
                if (data) {
                    this.$axios({
                        method: "post",
                        url: this.$apiURL + url,
                        data
                    }).then((res) => {
                        resove(res.data)
                    }).catch((err) => {
                        reject(err)
                    });
                }
                else {
                    this.$axios({
                        method: "get",
                        url: this.$apiURL + url,
                    }).then((res) => {
                        resove(res.data)
                    }).catch((err) => {
                        reject(err)
                    });
                }
            })

        },
        // 获取活动列表
        async getactivityList() {
            let { data } = await this.gedate('/api/index/activityList')
            console.log("活动列表", data);
            this.activeList = data
        },

    }
}
</script>

<style lang="scss" scoped>
.active {
    padding-top: 1.64rem;
    width: 100%;
    height: 10.46rem;
    background-color: #FDD23E;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin-bottom: 4.5rem;

    &-text {
        text-align: center;
        width: 66%;
        margin: 0 auto;

        &:nth-child(1) {
            font-size: .66rem;
            font-family: Galvji;
            font-weight: bold;
            color: #FFFFFF;
        }

        &:nth-child(2) {
            font-size: .42rem;
            font-family: Heiti SC;
            font-weight: 300;
            color: #FFFFFF;
            margin: .68rem auto;
        }

        &:nth-child(3) {
            font-size: .24rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: .42remx;
            text-indent: .5rem;
            text-align: left;
            margin-bottom: .9rem;
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        width: 12.75rem;
        margin: 0 auto;

        &-back {
            width: 33.3333%;
            height: 3.5rem;
            background-size: 100% 100%;
            cursor: pointer;

            div {
                width: 100%;
                height: 100%;
                display: flex;
                flex-flow: column;
                justify-content: center;
                border-radius: initial;

                &:hover {
                    opacity: 0;
                }

                p {
                    width: 80%;
                    margin: 0 auto;
                    text-align: left;

                    &:nth-child(1) {
                        font-size: .49rem;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #FFFFFF;
                        line-height: .41rem;
                        position: relative;
                        top: -0.4rem;
                    }

                    &:nth-child(2) {
                        font-size: .28rem;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: .35rem;
                    }

                    &:nth-child(3) {
                        font-size: .21rem;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: .35rem;
                        position: relative;
                        background-color: rgba(0, 0, 0, 0);
                        top: .2rem;
                    }
                }


            }

            &:nth-child(1) {
                div {
                    background-color: #FBC71C;
                }

            }

            &:nth-child(2) {
                div {
                    background-color: #FD8B9E;
                }

            }

            &:nth-child(3) {
                div {
                    background-color: #99DB68;
                }

            }

            &:nth-child(4) {
                div {
                    background-color: #F88C4B;
                }

            }

            &:nth-child(5) {
                div {
                    background-color: #FCC2C0;
                }

            }

            &:nth-child(6) {
                div {
                    background-color: #7590DE;
                }

            }
        }
    }
}

.title {
    width: 100%;
    height: 10.58rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;

    .back {
        width: 4.7rem;
        height: 8.07rem;
        background-color: #FBC71C;

        .poster {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            top: -0.2rem;
            left: -0.2rem;
            // background-color: blue;
            background-size: 100% 100%;
        }
    }

    .right {
        width: 11.97rem;
        height: 6.26rem;
        display: flex;
        flex-flow: column;
        justify-content: space-evenly;
        background-color: #ffffff;

        &-title {
            text-align: center;
            position: relative;
            width: 100%;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;

            p {
                position: relative;

                &:nth-child(1) {
                    font-size: .63rem;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #000000;
                    line-height: .48rem;
                    z-index: 2;
                }

                &:nth-child(2) {
                    font-size: .21rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                    line-height: .48rem;
                    margin-top: .25rem;
                }

                &:nth-child(3) {
                    width: 2.21rem;
                    height: .21rem;
                    background: #FBC71C;
                    border-radius: .08rem;
                    margin: 0 auto;
                    position: absolute;
                    top: .4rem;
                }
            }
        }

        &-list {
            display: flex;
            justify-content: space-evenly;

            &-back {
                width: 3.3rem;
                height: 3.3rem;
                background-color: #FBC71C;

                &-content {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    top: -0.15rem;
                    left: -0.15rem;


                }
            }
        }
    }
}

::v-deep .el-carousel__container {
    height: 100% !important;
    width: 100%;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

::v-deep .el-carousel__indicators--horizontal {
    display: none !important;
}

.center {
    width: 100%;
    height: 10.63rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    &-left {
        width: 8.65rem;
        height: 6.83rem;
        background-color: #FD8B9E;

        &-back {
            width: 9rem;
            position: relative;
            left: .4rem;
            top: -0.8rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            img {
                width: 30%;
                height: 1.77rem;
                object-fit: cover;

                &:nth-child(1) {
                    display: block;
                    width: 100%;
                    height: 5.4rem;
                    margin-bottom: .1rem;
                }
            }
        }
    }

    &-right {
        &-back {
            width: 3.59rem;
            height: 6.13rem;
            background-color: #FD8B9E;
            position: relative;
            top: -0.56rem;
            left: 0.16rem;

            div {
                width: 100%;
                height: 100%;
                position: relative;
                top: 0.16rem;
                left: -0.16rem;
                background-color: blue;
                background-size: 100% 100%;
            }
        }

        &-bottom {
            position: relative;


        }
    }
}

p {
    position: relative;
    text-align: center;

    &:nth-child(1) {
        font-size: .63rem;
        font-family: Source Han Sans CN;
        font-weight: 800;
        color: #000000;
        line-height: .48rem;
        z-index: 2;
    }

    &:nth-child(2) {
        font-size: .21rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: .48rem;
        margin-top: .25rem;
    }

    &:nth-child(3) {
        width: 3.44rem;
        height: .21rem;
        background: #FD8B9E;
        border-radius: .08rem;
        margin: 0 auto;
        position: absolute;
        top: .4rem;
    }
}

.bottom {
    width: 100%;

    &-title {
        width: 100%;
        height: 7rem;
        background-size: 100% 100%;
        position: relative;
        background-color: #FBC71C;

        &-poster {
            display: block;
            width: 6.17rem;
            height: 6.36rem;
            border: .19rem solid #ffffff;
            position: absolute;
            top: 1.99rem;
            left: 9.91rem;
            object-fit: cover;
            background-size: 100% 100%;
        }
    }

    &-center {
        height: 4.91rem;
        background-color: #ffffff;
        position: relative;

        &-autumn {
            position: relative;
            width: 2.52rem;
            margin-left: 2.69rem;
            margin-top: .63rem;

            p {
                &:nth-child(2) {
                    width: 4rem;
                    left: -.7rem;
                }

                &:nth-child(3) {
                    width: 2.52rem;
                    background-color: #99DB68;
                }
            }
        }

        &-imgs {
            width: 90%;
            display: flex;
            justify-content: space-evenly;
            margin: 0 5%;
            position: absolute;
            top: 1.72rem;

            &-back {
                width: 5.04rem;
                height: 5.52rem;
                background-color: #99DB68;

                &-content {
                    width: 4.61rem;
                    height: 4.2rem;
                    margin: 0 auto;
                    margin-top: .36rem;

                    .el-carousel__indicators {
                        display: none;
                    }
                }
            }

        }
    }

    &-bottom {
        width: 100%;
        height: 4.07rem;
        background-color: #F2F2F2;
    }





}
</style>